import NoSsr from '@material-ui/core/NoSsr'
import { StoryblokComponent } from '@sb-konzept/gatsby-source-storyblok'
import FullScreenLoader from 'components/FullScreenLoader'
import PageContext from 'components/PageContext'
import PageLayout from 'components/PageLayout'
import { graphql } from 'gatsby'
import * as R from 'ramda'
import React, { Suspense, useMemo } from 'react'
import { Helmet } from 'react-helmet'

// import standortHomepageDescription from '../utils/standortDescriptions'
import {
  createStandortDescription,
  createStandortTitle
} from '../utils/standortDescriptions'

function DojoHomepage(props) {
  const { data } = props

  const {
    page: {
      content: { body, layout }
    },
    siteInfo // : { content }
  } = data

  const {
    content: { location }
  } = siteInfo

  // console.log("temp djhp: ", data);

  const { header, footer } = useMemo(
    () =>
      R.applySpec({
        header: R.pathOr(null, [0, 'content', 'header', 0]),
        footer: R.pathOr(null, [0, 'content', 'footer', 0])
      })(layout),
    [layout]
  )

  return (
    <PageContext.Provider value={{ page: data.page }}>
      {header && <StoryblokComponent content={header} />}
      <Helmet>
        <title>{createStandortTitle(location, 'Homepage')}</title>
        <meta
          content={createStandortDescription(location, 'Homepage')}
          name="description"
        />
      </Helmet>

      <PageLayout>
        <NoSsr>
          <Suspense fallback={<FullScreenLoader />}>
            {siteInfo.content && (
              <StoryblokComponent content={siteInfo.content} />
            )}
            <StoryblokComponent
              content={data.page.content}
              siteInfo={siteInfo}
            />
            {/* {body?.map(content => (
              <StoryblokComponent
                key={content.id || content._uid}
                content={content}
              />
            ))} */}
          </Suspense>
        </NoSsr>
      </PageLayout>

      {footer && <StoryblokComponent content={footer} />}
    </PageContext.Provider>
  )
}

export default DojoHomepage

export const pageQuery = graphql`
  query($id: String!, $siteInfo: String!) {
    page: storyblokDojoHomepageStory(id: { eq: $id }) {
      ...storyblokDojoHomepageStory
    }
    siteInfo: storyblokDojoSiteInfoStory(full_slug: { eq: $siteInfo }) {
      ...storyblokDojoSiteInfoStory
    }
  }
`
